import { twMerge } from 'tailwind-merge'

type TSkeletonProps = {
    className?: string
}

export function Skeleton({ className }: TSkeletonProps) {
    return (
        <div
            className={twMerge(
                'animate-pulse bg-gray-200 rounded h-6 w-1/2',
                className
            )}
        ></div>
    )
}
