import React from 'react'
import { twMerge } from 'tailwind-merge'

type TRadioCardButtonProps = {
    checked: boolean
    onChange: () => void
    children: React.ReactNode
    className?: string
    disabled?: boolean
}

export function RadioCardButton({
    checked,
    onChange,
    children,
    className,
    disabled,
}: TRadioCardButtonProps) {
    return (
        <button
            disabled={disabled}
            className={twMerge(
                'border rounded transition-colors p-4 relative flex flex-col leading-none gap-4 text-left justify-between flex-1',
                checked
                    ? 'border-blue-bright bg-blue-bright bg-opacity-5 border-opacity-50'
                    : 'border-gray-100 ',
                disabled && 'opacity-50 cursor-not-allowed',
                className
            )}
            onClick={onChange}
            type="button"
        >
            {children}
            {checked && (
                <div className="w-full h-1 bg-blue-bright rounded-b absolute bottom-0 left-0" />
            )}
        </button>
    )
}

type RadioCardButtonTitleProps = {
    children: React.ReactNode
}

export function RadioCardButtonTitle({ children }: RadioCardButtonTitleProps) {
    return <div className="font-medium">{children}</div>
}

type TRadioCardButtonRowProps = {
    label?: React.ReactNode
    children: React.ReactNode
}

export function RadioCardButtonRow({
    label,
    children,
}: TRadioCardButtonRowProps) {
    return (
        <div className="flex justify-between text-lg w-full">
            {label && <span>{label}</span>}
            {children && <span className="font-semibold">{children}</span>}
        </div>
    )
}
